import {useEffect, useState} from "react";
import {adminApi, camelApi} from "../../aaxios";

async function runView(messageContent) {
    const answerInfo = {
        'user_query': messageContent,
    };
    try {
        const res = await camelApi.post('/run_view', answerInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return res.data
    } catch (e) {
        alert("시스템 점검 중")
    }
}


async function postAnswer(sessionId, answerNumber, question, answer) {
    try {
        const res = await adminApi.post('/answer/save', {
            'sessionId': sessionId,
            'answerNum': answerNumber,
            'question': question.text,
            'answer': answer,
        });
        return res.data
    } catch (error) {
        console.error('Error:', error);
    }
}

const EVT_INIT_MESSAGE = "EVT_INIT_MESSAGE"
const EVT_ADD_MESSAGE = "EVT_ADD_MESSAGE"
export const EVT_ADDED_MESSAGE = "EVT_ADDED_MESSAGE"

export default function useMessage({sessionId}) {
    const [messages, setMessages] = useState([])
    const [isMessageLoading, setIsMessageLoading] = useState(false)
    const [answerNum, setAnswerNum] = useState(0);
    const [messageEvent, setMessageEvent] = useState(EVT_INIT_MESSAGE)

    function addMessage(message) {

        setMessages((prevMessages) => [...prevMessages, message])
        setMessageEvent(EVT_ADD_MESSAGE)
        return true
    }


    useEffect(() => {
        if (messageEvent !== EVT_ADD_MESSAGE) return

        setIsMessageLoading(true)

        async function sendMessage() {
            const lastMessage = messages[messages.length - 1]
            const runData = await runView(lastMessage.text)
            const answer = runData["output"]
            setMessages(prevMessages => [...prevMessages, {text: answer, sender: 'bot'}]);
            await postAnswer(sessionId, answerNum, lastMessage, answer)
            setAnswerNum(prevAnswerNum => prevAnswerNum + 1)
            setIsMessageLoading(false)
            setMessageEvent(EVT_ADDED_MESSAGE)
        }

        sendMessage().then()
    }, [messageEvent]);

    return {
        isMessageLoading,
        messages,
        addMessage,
        messageEvent
    }

}