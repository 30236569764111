import React, {useState} from "react";
import useOutsideClick from "../../hooks/view/useOutsideClick";


export default function ConsultationModal(props) {
    const {open, onSubmit, onClose} = props
    const [name, setName] = useState('')
    const [contact, setContact] = useState('')
    const {outsideRef} = useOutsideClick({onClose})

    if (!open) return null

    async function handleSubmit(e) {
        e.stopPropagation()
        if (onSubmit) {
            const res = await onSubmit({
                name, contact
            })
        }
    }

    return (
        <div className="modal" ref={outsideRef}>
            <div>
                <div className='modal-header'>
                    <p className='modal-title'>문의하기</p>
                </div>
                <div className='modal-header'>
                    <p className='modal-subtitle'>이름과 연락처를 남겨주시면 문의하신 내용에 대한 자세한 답변을
                        드리겠습니다.</p>
                </div>
                <div>
                    <p className='modal-input-text'>이름</p>
                    <input className='modal-input' type="text" value={name}
                           onChange={(e) => setName(e.target.value)}
                           placeholder='이름을 입력해주세요'/>
                </div>
                <div>
                    <p className='modal-input-text'>연락처</p>
                    <input className='modal-input' type="text" inputMode="tel"
                           pattern="[0-9]{11}" maxLength="11" value={contact}
                           onChange={(e) => setContact(e.target.value)}
                           placeholder="'-' 없이 입력"/>
                </div>
                <button type={'button'}
                        style={{cursor: "pointer"}}
                        onClick={handleSubmit} className='modal-submit'>완료
                </button>
            </div>
        </div>

    )
}