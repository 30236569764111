import {useEffect, useRef} from "react";

export default function useOutsideClick({onClose}) {
    const outsideRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            // 모달 바깥을 클릭했는지 확인
            if (outsideRef.current && !outsideRef.current.contains(event.target)) {
                onClose(); // 모달 닫기
            }
        }

        // 이벤트 리스너 추가
        document.addEventListener("mousedown", handleClickOutside);
        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]);

    return {
        outsideRef
    }
}