import {useRef} from "react";


export default function useScroll() {
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };

    return {
        messagesEndRef,
        scrollToBottom
    }
}