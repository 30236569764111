import React, {useState} from "react";


export default function InputBar(props) {
    const {onSubmit} = props
    const [text, setText] = useState('');

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation()
        if (!onSubmit) return
        if (text.trim() === '') return
        const res = await onSubmit({
            text
        })
        if (res) {
            setText('')
        }
    }

    async function handleKeyPress(e) {
        if (e.key === 'Enter') {
            await handleSubmit(e);
        }
    }

    return (
        <div className='msger-inputarea'>
            <img style={{float: 'left', paddingTop: '12px', marginRight: '10px', cursor: "pointer"}}
                 src="img/home.svg" alt="home"
                 onClick={() => window.location.reload()}></img>
            <input
                className="msger-input"
                id="msger-input"
                type="text"
                value={text}
                onKeyUp={handleKeyPress}
                onChange={(e) => setText(e.target.value)}
                placeholder='무엇이든 물어보세요'
            />
            <button className="send-btn" onClick={handleSubmit}>
                <img style={{width: '35px', paddingTop: '0px', cursor: "pointer"}} src="img/send.svg"
                     alt="send"></img>
            </button>
        </div>
    )
}