import React, {useState} from "react";
import ConsultationModal from "../Components/ChatBot/ConsultationModal";
import ChatBotHeader from "../Components/ChatBot/ChatBotHeader";
import OnceViewPopUp from "../Components/ChatBot/OnceViewPopUp";
import Loading from "../Components/ChatBot/Loading";
import FAQChipList from "../Components/ChatBot/FAQChipList";
import InputBar from "../Components/ChatBot/InputBar";
import MessageBody from "../Components/ChatBot/MessageBody";
import useFaq from "../hooks/domain/useFaq";
import useMessage from "../hooks/domain/useMessage";
import useScreen from "../hooks/view/useScreen";
import useConsultation from "../hooks/domain/useConsultation";
import useSession from "../hooks/domain/useSession";

import '../App.css';

const GREETING_MESSAGE = '안녕하세요. 뷰성형외과 입니다. 무엇이 궁금하신가요'

export default function ChatBot(props) {
    const {sessionId} = useSession()
    const [modalOpen, setModalOpen] = useState(false);
    const {messages, addMessage, messageEvent, isMessageLoading} = useMessage({
        sessionId
    })
    const {faqs} = useFaq()
    const {submitUserInfo} = useConsultation()
    useScreen()


    function handleClickFaq(faq) {
        addMessage({
            text: faq,
            sender: 'user'
        })
    }

    function handleSubmitInput({text}) {
        return addMessage({
            text,
            sender: 'user'
        })
    }

    function openModal() {
        setModalOpen(true)
    }

    function closeModal() {
        setModalOpen(false)
    }

    async function onSubmitConsultation({
                                            name,
                                            contact
                                        }) {
        const reqData = {
            sessionId,
            name,
            contact
        }
        const res = await submitUserInfo(reqData)
        alert(res.message)
        if (res.success) {
            closeModal()
        }
    }

    return (
        <>
            <OnceViewPopUp/>
            <div className={`chat-container ${isMessageLoading ? 'blurred' : ''}`}>
                {modalOpen && <div className="blurred-input-background"/>}
                <div className='wrap'>
                    <div className='container'>
                        <Loading isLoading={isMessageLoading}/>
                        <div className='msger'>
                            <ChatBotHeader onConsultation={openModal}/>
                            <main className='msger-chat msger-chat-frame'>
                                <ConsultationModal open={modalOpen}
                                                   onSubmit={onSubmitConsultation}
                                                   onClose={closeModal}/>
                                <section className='msger-chat-prompt'>
                                    <div className='scroll-prompt'>
                                        <div className='greeting'>{GREETING_MESSAGE}</div>
                                        <MessageBody messages={messages}
                                                     messageEvent={messageEvent}/>
                                    </div>
                                </section>
                                <div className='msger-form'>
                                    <FAQChipList faqs={faqs} onClick={handleClickFaq}/>
                                    <InputBar onSubmit={handleSubmitInput}/>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}