import React from "react";

export default function ChatBotHeader(props) {
    const {onConsultation} = props

    function handleConsultation(e) {
        e.stopPropagation()
        if (onConsultation) {
            onConsultation()
        }
    }

    return (
        <header className='msger-header'>
            <div className='msger-header-title'>
                <div className='header-logo-frame'>
                    <img src='img/viewbeauty_01.svg' alt="Logo"></img>
                </div>
            </div>
            <button className="rounded-chip-admin"
                    style={{cursor:'pointer'}}
                    onClick={handleConsultation}>문의하기</button>
        </header>
    )
}