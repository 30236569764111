import {useEffect, useState} from "react";
import {adminApi} from "../../aaxios";



async function makeSessionId() {
    try {
        const response = await adminApi.get('/session/get_id');
        return response.data.result;
    } catch (error) {
        return null
    }
}



export default function useSession(){
    const [sessionId, setSessionId] = useState(null);
    useEffect(() => {
        makeSessionId().then(data => {
            console.log("session_id >", data)
            setSessionId(data)
        })
    }, []);

    return {
        sessionId
    }
}