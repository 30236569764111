import {useEffect} from "react";

export default function useScreen() {
    // 모바일 100vh issue 해결을 위한 스크립트

    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        const setScreenSize = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setScreenSize();
        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);
        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

}