import React, {useState} from "react";

// deprecated 된 것 같은데?

export default function OnceViewPopUp(props) {
    const [view, setView] = useState(true)

    if (!view) {
        return
    }

    return (
        <div className="popup-background">
            <div className="popup">
                <p>문의하기 버튼을 통해 이름과 연락처를 남겨주시면 문의하신 내용에 대한 자세한 답변을 드리겠습니다.</p>
                <button className='modal-submit' onClick={() => setView(false)}>확인</button>
            </div>
        </div>
    )
}