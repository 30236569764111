import {adminApi} from "../../aaxios";
import {useEffect, useState} from "react";

async function fetchFAQList() {
    try {
        const response = await adminApi.get('/faq');
        if (response.status === 200) {
            return response.data.map(item => item.faq)
        }
    } catch (err) {
        return []
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // 요소를 서로 바꿈
    }
    return array;
}

export default function useFaq() {
    const [faqs, setFaqs] = useState([])
    useEffect(() => {
        fetchFAQList().then(data => {
            setFaqs(data)
        })
    }, [])

    return {
        faqs: shuffleArray(faqs),
    }
}