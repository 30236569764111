import axios from 'axios';

const adminApi = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_BASE_URL,
});


const camelApi = axios.create({
    baseURL: process.env.REACT_APP_CAMEL_API_BASE_URL,
});

export {
    adminApi,
    camelApi,
};