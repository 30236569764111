import React from "react";


export default function FAQChipList(props) {
    const {faqs, onClick} = props

    function handleClick(e, faq) {
        e.stopPropagation()
        onClick && onClick(faq)
    }

    return (
        <div className='chips-wrapper' id='chips-wrapper'>
            {faqs.map((faq, index) => (
                <div key={index} className="rounded-chip"
                     onClick={(e) => handleClick(e, faq)}>
                    {faq}
                </div>
            ))}
        </div>
    )
}